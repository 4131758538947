<template>
  <div class="lesson">
    <!-- MAPRIMERENOV -->
    <app-block-fiche-aide-fi :aide="maprimerenov">
      <template #montant>
        <p class="left-content" v-html="$t('module1.part2.aides.maprimerenov.montantIntro')"></p>
        <div class="list-wrapper">
          <div class="flex-icon-block">
            <img :src="require('@/assets/module1/part2/lesson/tres-modeste-ico.svg')"/>
            <p>{{ $t('module1.part2.aides.maprimerenov.montantPlafond1') }}</p>
          </div>
          <div class="flex-icon-block">
            <img :src="require('@/assets/module1/part2/lesson/modeste-ico.svg')"/>
            <p>{{ $t('module1.part2.aides.maprimerenov.montantPlafond2') }}</p>
          </div>
          <div class="flex-icon-block">
            <img :src="require('@/assets/module1/part2/lesson/intermediaire-ico.svg')"/>
            <p>{{ $t('module1.part2.aides.maprimerenov.montantPlafond3') }}</p>
          </div>
          <div class="flex-icon-block">
            <img :src="require('@/assets/module1/part2/lesson/aise-ico.svg')"/>
            <p>{{ $t('module1.part2.aides.maprimerenov.montantPlafond4') }}</p>
          </div>
        </div>
        <montant-mpr-modal class="modal-button" :button-label="$t('module1.part2.aides.maprimerenov.montantModalButton')" />
      </template>
    </app-block-fiche-aide-fi>

    <!-- TVA55 -->
    <app-block-fiche-aide-fi :aide="tva55" />

    <app-button-layout class="next-button" @click="$emit('next-tab')">
      {{ $t('global.navigation.resume') }}
    </app-button-layout>
  </div>
</template>

<script setup>
import AppBlockFicheAideFi from '@/components/training/module1/part2/AppBlockFicheAideFi.vue'
import { useI18n } from 'vue-i18n'
import MontantMprModal from '@/components/training/MontantMprModal.vue'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'

const { t } = useI18n()

const maprimerenov = {
  name: 'maprimerenov',
  label: t('module1.part2.aides.maprimerenov.title'),
  logo: require('@/assets/logos/aides/logo-mpr.svg'),
  intro: t('module1.part2.aides.maprimerenov.intro'),
  logement: t('module1.part2.aides.maprimerenov.logement'),
  qui: t('module1.part2.aides.maprimerenov.qui'),
  montant: '',
  travaux: {
    intro: t('module1.part2.aides.maprimerenov.travauxIntro'),
    renoAmpleurAnnotation: t('module1.part2.aides.maprimerenov.renoAmpleurAnnotation'),
    items: [
      'isolation',
      'menuiserie',
      'chauffage',
      'vmc',
      'renoAmpleur'
    ]
  },
  obtention: [
    t('module1.part2.aides.maprimerenov.obtention.item1'),
    t('module1.part2.aides.maprimerenov.obtention.item2'),
    t('module1.part2.aides.maprimerenov.obtention.item3'),
    t('module1.part2.aides.maprimerenov.obtention.item4')
  ]
}

const tva55 = {
  name: 'tva55',
  label: t('module1.part2.aides.tva55.title'),
  logo: require('@/assets/logos/aides/logo-tva55.svg'),
  intro: t('module1.part2.aides.tva55.intro'),
  logement: t('module1.part2.aides.tva55.logement'),
  qui: t('module1.part2.aides.tva55.qui'),
  travaux: {
    intro: t('module1.part2.aides.tva55.travauxIntro'),
    items: [
      'isolation',
      'menuiserie',
      'chauffage',
      'induits'
    ]
  },
  obtention: [
    t('module1.part2.aides.tva55.obtention')
  ]
}
</script>

<style scoped lang="scss">
.lesson {
  .list-wrapper {
    margin: $space-m 0;
  }
}
</style>
